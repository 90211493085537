.home-section {
    height: 100vh;
    background: url(../images/background.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;

    & .intro {
        /* position: relative; */
        color: var(--lightred);
        /* top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); */
        font-family: var(--font-home);
        text-align: center;
        color: white;
        background: rgba(11, 33, 64, 0.6);
        padding: 10px; 
        width: 100%;
        
        & p {
        font-family: Playfair Display;
        padding: 10px;
        }

        & h1 span {
        color: var(--lightred);
        }

        & .typing {
            font-weight: 700;
            font-family: var(--font-page-headings);
            margin-bottom: 20px;
        }
    }
}