.footer {
    width: 100%;
    background-color: var(--darkblue);
    color: var(--white);
    padding: 30px 0;

    & .row {
        display: flex;
        justify-content: center;

    }

    & svg {
        margin: 18px;
        font-size: calc(var(--font-size-base)*2);
        color: var(--lightred);
    }
}