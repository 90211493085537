#experience {
    background-color: var(--lightgray);

    & .experience-section {
        max-width: 90%;
        margin: 0px auto;
        padding: 30px 0;
    }

    & .experience-entry {
        background-color: var(--white);
        margin: 10px;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
        margin-bottom: 50px;
    }

    & .entry-header {
        background-color: var(--darkblue);
        padding: 15px;
    
        & h3 {
            font-size: calc(var(--font-size-base)*.9);
            font-weight: var(--font-weight-bold);
            color: var(--white);
            margin-bottom: 0;
            padding-bottom: 0;
            text-align: left;
        }
    
        & h4{
            margin-top: 0;
            padding-top: 0;
            text-align: left;
            font-size: var(--font-size-base);
            font-weight: var(--font-weight-bold);
            color: var(--lightred);
        }
    }

    & .entry-description {
        padding: 20px;
    }

    & .section-main {
        @media  (min-width: 1150px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            & .experience-entry {
                width: 45%;
            }
        }     

    }

}