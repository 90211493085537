@import url(https://fonts.googleapis.com/css?family=Baloo|Exo+2:100,400,500,700|Josefin+Sans:100,400,700|Montserrat:100,400,500,700|Playfair+Display:400,700|Raleway:100,400,400i,500,500i,700,700i|Sacramento&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: relative;
  width: 36px;
  height: 30px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ff4d5a;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #0b2140;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #0b2140;
}

/* Wrapper for item list */
.bm-item-list {
  color: #fff;
  padding: 0.8em;

}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  -webkit-transform: translate(-100%);
          transform: translate(-100%);
}

.cv {
  color: #fff !important;
}

.cv:visited {
  color: #fff !important;
}

.cv:hover {
  color: #ff4d5a !important;
}
.scroll .link {
    position: absolute;
    bottom: 20px;
    left: 50%;
    right: 50%;
    z-index: 2;
    display: inline-block;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    color: #fff;
    font : normal 400 20px/1 'Josefin Sans', sans-serif;
    letter-spacing: .1em;
    text-decoration: none;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
  }
  .scroll .link:hover {
    opacity: .5;
  }
  
  
  #scrollDown .link {
    padding-top: 60px;
  }
  #scrollDown span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 46px;
    height: 46px;
    margin-left: -23px;
    border: 1px solid #fff;
    border-radius: 100%;
    box-sizing: border-box;
  }
  #scrollDown span::after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    width: 16px;
    height: 16px;
    margin: -12px 0 0 -8px;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    box-sizing: border-box;
  }
  #scrollDown span::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    content: '';
    width: 44px;
    height: 44px;
    box-shadow: 0 0 0 0 rgba(255,255,255,.1);
    border-radius: 100%;
    opacity: 0;
    -webkit-animation: sdb03 3s infinite;
    animation: sdb03 3s infinite;
    box-sizing: border-box;
  }
  @-webkit-keyframes sdb03 {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    60% {
      box-shadow: 0 0 0 60px rgba(255,255,255,.1);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes sdb03 {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    60% {
      box-shadow: 0 0 0 60px rgba(255,255,255,.1);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  
:root {
  --white: #fff;
  --lightgray: #f5f5f5;
  --black: #000;
  --lightred: #ff4d5a;
  --darkblue: #0b2140;
  
  --font-priamary: 'Raleway', sans-serif;
  --font-headline: 'Montserrat', sans-serif;
  --font-page-headings: 'Exo 2', sans-serif;
  --font-handwriting: 'Sacramento', cursive;
  --font-skills: 'Baloo', cursive;
  --font-home: 'Josefin Sans', sans-serif;
  
  --font-size-base: 1.3rem;
  --line-height-base: 150%;
  
  --font-weight-light: 100;
  --font-weight-normal: 400;
  --font-weight-medium: 600;
  --font-weight-bold: 700;
  
  --container-default-width: 90%;
  --container-max-width: 1200px;
  
  
  @custom-media --viewport-small (max-width: 576px);
  @custom-media --viewport-medium (max-width: 768px);
  @custom-media --viewport-large (max-width: 992px);
  @custom-media --viewport-extralarge (max-width: 1200px);   
  
}
body {  
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
    text-align: center;
    margin: 0;
}

h2 {
    font-family: var(--font-page-headings);
    font-size: 2rem;
    color: var(--darkblue);   
    margin-bottom: 10px;
}

h3, h4, h5, h6 {
    font-family: var(--font-headline);
    font-weight: var(--font-weight-norma);
}

p {
    font-family: var(--font-priamary);
    margin: 0;
    padding: 1rem;
}

a:link {
    text-decoration: none;
    color: var(--darkblue);
}
a:visited {
    text-decoration: none;
    color: var(--darkblue);
}

a:hover {
    color: var(--lightred);
}

.logo {
    height: 100px;
    width: 100px;
}

.header {
    width: 80%;
    display: flex;
    justify-content: space-between;
    position: fixed;
    margin: 0 auto;
    left: 0;
    right: 0;
}

.section-header {
    margin-bottom: 5rem;
}

.separator {
    margin: 0 auto;
    height: 4px;
    width: 70px;
    background-color: var(--lightred);
    margin-bottom: 2rem;
}

.scroll-top {
    display: none
}

@media (min-width: 1100px) {

.scroll-top {
        display: block;
        opacity: 0.3;
        background-color: var(--lightred);
        width: 50px;
        height: 50px;
        position: fixed;
        bottom: 40px;
        right: 40px;
        border-radius: 5px;
        border: none;
        -webkit-transition: 0.5s;
        transition: 0.5s
}
        
        .scroll-top:hover {
          opacity: 1;
        }
    }

i {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}
.arrow-up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.signature {
    font-family: var(--font-handwriting);
    font-size: calc(var(--font-size-base)*1.5);
    text-align: left;
    margin-bottom: 20px;
}
.home-section {
    height: 100vh;
    background: url(/static/media/background.4ade390d.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center
}
.home-section .intro {
        /* position: relative; */
        color: var(--lightred);
        /* top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); */
        font-family: var(--font-home);
        text-align: center;
        color: white;
        background: rgba(11, 33, 64, 0.6);
        padding: 10px; 
        width: 100%
    }
.home-section .intro p {
        font-family: Playfair Display;
        padding: 10px;
        }
.home-section .intro h1 span {
        color: var(--lightred);
        }
.home-section .intro .typing {
            font-weight: 700;
            font-family: var(--font-page-headings);
            margin-bottom: 20px;
        }

    
    #about .about-section {
        max-width: 90%;
        margin: 30px auto;
    }
            
#about .image img {
        height: 400px;
  

    }
            
#about .info {
        /* padding: 30px; */
    }
            
#about .info h3 {
            font-weight: var(--font-weight-bold);
        }
            
@media  (min-width: 1100px) {
            
        #about .section-main {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center

    }
            
            #about .section-main .info {
                margin-bottom: 50px;
                box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
                border: 2px solid var(--darkblue);
                width: 50%;
            }

            #about .section-main .image img {
                height: 600px;
                text-align: center;
            }
        }
            
@media  (min-width: 1300px) {
            
        #about .about-section {
            margin: 0 auto

    }

            #about .about-section .image img {
                height: 100%;
                text-align: center;
            }
        }
#experience {
    background-color: var(--lightgray)

}

#experience .experience-section {
        max-width: 90%;
        margin: 0px auto;
        padding: 30px 0;
    }

#experience .experience-entry {
        background-color: var(--white);
        margin: 10px;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
        margin-bottom: 50px;
    }

#experience .entry-header {
        background-color: var(--darkblue);
        padding: 15px
    }

#experience .entry-header h3 {
            font-size: calc(var(--font-size-base)*.9);
            font-weight: var(--font-weight-bold);
            color: var(--white);
            margin-bottom: 0;
            padding-bottom: 0;
            text-align: left;
        }

#experience .entry-header h4{
            margin-top: 0;
            padding-top: 0;
            text-align: left;
            font-size: var(--font-size-base);
            font-weight: var(--font-weight-bold);
            color: var(--lightred);
        }

#experience .entry-description {
        padding: 20px;
    }

@media  (min-width: 1150px) {

    #experience .section-main {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between     

    }

            #experience .section-main .experience-entry {
                width: 45%;
            }
        }
#portfolio {
    background-color: var(--lightgray)
}
    
#portfolio h3 {
        font-family: var(--font-skills);
    }
    
#portfolio .portfolio-section {
        max-width: 90%;
        margin: 0px auto;
        padding: 30px 0
    }
    
#portfolio .portfolio-section .project {
            margin: 10px;
            background-color: var(--white);
            box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
            margin-bottom: 50px
        }
    
#portfolio .portfolio-section .project img {
                width: 100%;
            }
    
#portfolio .portfolio-section .project .project-title{
                padding-left: 30px;
                text-align: left;
            }
    
#portfolio .portfolio-section .project .separator {
                width: 150px;
            }
    
@media  (min-width: 768px) {
    
    #portfolio .portfolio-section .section-main {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between     
        }
    
                #portfolio .portfolio-section .section-main .project {
                    width: 45%;
                }
            }


    #skills .skills-section {
        max-width: 90%;
        margin: 0px auto;
        padding: 30px 0
    }

#skills .skills-section .wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px
        }

#skills .skills-section .wrapper .skill-logo {
                width: 15%
            }

#skills .skills-section .wrapper .skill-logo img {
                    max-width: 100%;
                    vertical-align: middle;
                }

#skills .skills-section .wrapper .skill-details {
                width: 80%
            }

#skills .skills-section .wrapper .skill-details .skill-meta {
                    display: flex;
                    align-items: flex-end            
                }

#skills .skills-section .wrapper .skill-details .skill-meta p {
                        margin: 0;
                        padding: 0px 20px;
                        font-size: calc(var(--font-size-base)*.5);
                    }

#skills .skills-section .wrapper .skill-details .skill-meta h3 {
                        margin: 0;
                        font-size: calc(var(--font-size-base)*.9);
                        font-family: var(--font-skills);
                        font-weight: 300;
                    }

#skills .skills-section .wrapper .skill-details .skill-bar {
                    max-width: 100%;
                }

@media  (min-width: 768px) {

        #skills .section-main {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between     
    }

            #skills .section-main .wrapper {
                width: 45%;
            }
        }

#skills .react-sweet-progress-line {
        box-shadow: 0 5px 15px 0 rgba(7, 33, 66, 0.15);
        z-index: -1;
    }
.contact-section {
    max-width: 90%;
    margin: 0 auto;
    padding: 30px 0
}
.contact-section p {
        margin-bottom: 2rem;
    }
.contact-section .contact-me {
        width: 80%;
        margin: 0 auto
    }
.contact-section .contact-me input {
            width: 95%;
            padding: 10px;
            border: 1px solid #ccc;
            font-family: var(--font-priamary);
            font-size: var(--font-size-base);
        }
.contact-section .contact-me textarea {
            width: 95%;
            padding: 10px;
            border: 1px solid #ccc;
            font-family: var(--font-priamary);
            font-size: var(--font-size-base);
        }
.contact-section .contact-me button {
            font-size: calc(var(--font-size-base)*.5);
            padding: 10px 30px;
            color: var(--white);
            background: var(--lightred);
            outline: 0;
            margin: 20px
        }
.contact-section .contact-me span {
            font-family: var(--font-priamary);
            color: var(--lightred);
        }
.footer {
    width: 100%;
    background-color: var(--darkblue);
    color: var(--white);
    padding: 30px 0
}
.footer .row {
        display: flex;
        justify-content: center;

    }
.footer svg {
        margin: 18px;
        font-size: calc(var(--font-size-base)*2);
        color: var(--lightred);
    }


