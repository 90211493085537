#skills {

    & .skills-section {
        max-width: 90%;
        margin: 0px auto;
        padding: 30px 0;

        & .wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;
        
            & .skill-logo {
                width: 15%;
        
                & img {
                    max-width: 100%;
                    vertical-align: middle;
                }
            }
        
            & .skill-details {
                width: 80%;
        
                & .skill-meta {
                    display: flex;
                    align-items: flex-end;
        
                    & p {
                        margin: 0;
                        padding: 0px 20px;
                        font-size: calc(var(--font-size-base)*.5);
                    }
        
                    & h3 {
                        margin: 0;
                        font-size: calc(var(--font-size-base)*.9);
                        font-family: var(--font-skills);
                        font-weight: 300;
                    }            
                }
    
                & .skill-bar {
                    max-width: 100%;
                }
            }
        }
    }

    & .section-main {
        @media  (min-width: 768px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            & .wrapper {
                width: 45%;
            }
        }     
    }

    & .react-sweet-progress-line {
        box-shadow: 0 5px 15px 0 rgba(7, 33, 66, 0.15);
        z-index: -1;
    }
}