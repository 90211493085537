body {  
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
    text-align: center;
    margin: 0;
}

h2 {
    font-family: var(--font-page-headings);
    font-size: 2rem;
    color: var(--darkblue);   
    margin-bottom: 10px;
}

h3, h4, h5, h6 {
    font-family: var(--font-headline);
    font-weight: var(--font-weight-norma);
}

p {
    font-family: var(--font-priamary);
    margin: 0;
    padding: 1rem;
}

a:link {
    text-decoration: none;
    color: var(--darkblue);
}
a:visited {
    text-decoration: none;
    color: var(--darkblue);
}

a:hover {
    color: var(--lightred);
}

.logo {
    height: 100px;
    width: 100px;
}

.header {
    width: 80%;
    display: flex;
    justify-content: space-between;
    position: fixed;
    margin: 0 auto;
    left: 0;
    right: 0;
}

.section-header {
    margin-bottom: 5rem;
}

.separator {
    margin: 0 auto;
    height: 4px;
    width: 70px;
    background-color: var(--lightred);
    margin-bottom: 2rem;
}

.scroll-top {
    display: none;

    @media (min-width: 1100px) {
        display: block;
        opacity: 0.3;
        background-color: var(--lightred);
        width: 50px;
        height: 50px;
        position: fixed;
        bottom: 40px;
        right: 40px;
        border-radius: 5px;
        border: none;
        transition: 0.5s;
        
        &:hover {
          opacity: 1;
        }
    }
}

i {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}
.arrow-up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.signature {
    font-family: var(--font-handwriting);
    font-size: calc(var(--font-size-base)*1.5);
    text-align: left;
    margin-bottom: 20px;
}