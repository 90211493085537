@import url('https://fonts.googleapis.com/css?family=Baloo|Exo+2:100,400,500,700|Josefin+Sans:100,400,700|Montserrat:100,400,500,700|Playfair+Display:400,700|Raleway:100,400,400i,500,500i,700,700i|Sacramento&display=swap');

:root {
  --white: #fff;
  --lightgray: #f5f5f5;
  --black: #000;
  --lightred: #ff4d5a;
  --darkblue: #0b2140;
  
  --font-priamary: 'Raleway', sans-serif;
  --font-headline: 'Montserrat', sans-serif;
  --font-page-headings: 'Exo 2', sans-serif;
  --font-handwriting: 'Sacramento', cursive;
  --font-skills: 'Baloo', cursive;
  --font-home: 'Josefin Sans', sans-serif;
  
  --font-size-base: 1.3rem;
  --line-height-base: 150%;
  
  --font-weight-light: 100;
  --font-weight-normal: 400;
  --font-weight-medium: 600;
  --font-weight-bold: 700;
  
  --container-default-width: 90%;
  --container-max-width: 1200px;
  
  
  @custom-media --viewport-small (max-width: 576px);
  @custom-media --viewport-medium (max-width: 768px);
  @custom-media --viewport-large (max-width: 992px);
  @custom-media --viewport-extralarge (max-width: 1200px);   
  
}