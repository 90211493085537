#about {
    
    & .about-section {
        max-width: 90%;
        margin: 30px auto;
    }

    & .image img {
        height: 400px;
  

    }
    & .info {
        /* padding: 30px; */

        & h3 {
            font-weight: var(--font-weight-bold);
        }
    }
    
    & .section-main {
        @media  (min-width: 1100px) {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
            
            & .info {
                margin-bottom: 50px;
                box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
                border: 2px solid var(--darkblue);
                width: 50%;
            }

            & .image img {
                height: 600px;
                text-align: center;
            }
        }

    }

    & .about-section {
        @media  (min-width: 1300px) {
            margin: 0 auto;

            & .image img {
                height: 100%;
                text-align: center;
            }
        }

    }
}