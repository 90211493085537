.contact-section {
    max-width: 90%;
    margin: 0 auto;
    padding: 30px 0;

    & p {
        margin-bottom: 2rem;
    }

    & .contact-me {
        width: 80%;
        margin: 0 auto;

        & input {
            width: 95%;
            padding: 10px;
            border: 1px solid #ccc;
            font-family: var(--font-priamary);
            font-size: var(--font-size-base);
        }
        
        & textarea {
            width: 95%;
            padding: 10px;
            border: 1px solid #ccc;
            font-family: var(--font-priamary);
            font-size: var(--font-size-base);
        }

        & button {
            font-size: calc(var(--font-size-base)*.5);
            padding: 10px 30px;
            color: var(--white);
            background: var(--lightred);
            outline: 0;
            margin: 20px
        }

        & span {
            font-family: var(--font-priamary);
            color: var(--lightred);
        }
    }
}