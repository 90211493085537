#portfolio {
    background-color: var(--lightgray);

    & h3 {
        font-family: var(--font-skills);
    }

    & .portfolio-section {
        max-width: 90%;
        margin: 0px auto;
        padding: 30px 0;

        & .project {
            margin: 10px;
            background-color: var(--white);
            box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
            margin-bottom: 50px;
    
            & img {
                width: 100%;
            }
    
            & .project-title{
                padding-left: 30px;
                text-align: left;
            }
    
            & .separator {
                width: 150px;
            }
        }

        & .section-main {
            @media  (min-width: 768px) {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
    
                & .project {
                    width: 45%;
                }
            }     
        }
    }
}